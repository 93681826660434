<template>
  <v-card>
    <v-card-title>{{ $t('PhysicalExamination') }}</v-card-title>
    <v-card-text class="d-flex justify-space-between">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="userData.face_image_url || require('@/assets/images/avatars/1.png')"></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div class="text-right">
        <v-btn
          v-if="canDelete"
          color="error"
          width="100%"
          min-width="100"
          small
          @click="isShowDeleteDialog = true"
        >
          {{ $t(!edit ? 'Remove' : 'Delete') }}
        </v-btn>
        <v-btn
          v-if="!edit"
          outlined
          class="mt-3"
          width="100%"
          small
          @click="handleWait"
        >
          {{ $t('Wait') }}
        </v-btn>
        <v-btn
          outlined
          class="mt-3"
          width="100%"
          small
          @click="$router.push({name: edit ? 'staff-town-exam-list' : 'staff-town-infirmary'})"
        >
          {{ $t('Back') }}
        </v-btn>
      </div>
    </v-card-text>
    <v-card-text>
      <v-card
        outlined
      >
        <v-card-text
          class="pa-2"
        >
          <div>
            {{ $t('Name') }}:
          </div>
          <div class="font-weight-bold">
            {{ userData.full_name || '-' }}
          </div>
          <div>
            {{ $t('PhoneNumber') }}:
          </div>
          <div class="font-weight-bold">
            {{ userData.phone || '-' }}
          </div>
          <div class="d-flex justify-space-between">
            <div class="flex-grow-1">
              <div>
                {{ $t('Gender') }}:
              </div>
              <div class="font-weight-bold">
                {{ userData.gender !== undefined ? (userData.gender ? $t('Male') : $t('Female')) : "-" }}
              </div>
            </div>
            <div class="flex-grow-1">
              <div>
                {{ $t('Age') }}:
              </div>
              <div class="font-weight-bold">
                {{ userData.year_of_birth ? new Date().getFullYear() - userData.year_of_birth : '-' }}
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text>
      <v-form
        ref="checkUpForm"
        @submit.prevent="handleSubmit"
      >
        <div class="d-flex">
          <div
            class="flex-grow-1 mr-2"
          >
            <v-text-field
              v-model.number="height"
              dense
              outlined
              type="number"
              :label="$t('Height')"
              :placeholder="$t('Height')"
              :prepend-inner-icon="icons.mdiHumanMaleHeight"
              hide-details="auto"
              class="mb-4"
              validate-on-blur
              suffix="cm"
              :rules="[validators.required]"
              :readonly="!canEdit"
            ></v-text-field>
            <v-text-field
              v-model.number="weight"
              dense
              outlined
              type="number"
              :label="$t('Weight')"
              :placeholder="$t('Weight')"
              :prepend-inner-icon="icons.mdiWeightLifter"
              hide-details="auto"
              class="mb-4"
              validate-on-blur
              suffix="kg"
              :rules="[validators.required]"
              :readonly="!canEdit"
            ></v-text-field>
          </div>
          <v-card
            class="text-center ml-2 mb-4"
            style="width:50%"
          >
            <v-card-text class="pa-3">
              <div>BMI <v-icon>{{ icons.mdiTabMinus }}</v-icon></div>
              <div class="font-weight-bold text-h6">
                {{ bmi || 0 }}
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="d-flex">
          <v-text-field
            v-model.number="blood_pressure"
            dense
            outlined
            type="number"
            :label="$t('BloodPressure')"
            :placeholder="$t('BloodPressure')"
            :prepend-inner-icon="icons.mdiBloodBag"
            hide-details="auto"
            class="mb-4 mr-2"
            validate-on-blur
            :rules="[validators.required]"
            :readonly="!canEdit"
          ></v-text-field>
          <div class="mt-2">
            /
          </div>
          <v-text-field
            v-model.number="blood_pressure_2"
            dense
            outlined
            type="number"
            :label="$t('BloodPressure')"
            :placeholder="$t('BloodPressure')"
            :prepend-inner-icon="icons.mdiBloodBag"
            hide-details="auto"
            class="mb-4 ml-2"
            validate-on-blur
            :rules="[validators.required]"
            :readonly="!canEdit"
          ></v-text-field>
        </div>

        <v-text-field
          v-model.number="body_fat"
          dense
          outlined
          type="number"
          :label="$t('BodyFat')"
          :placeholder="$t('BodyFat')"
          :prepend-inner-icon="icons.mdiNaturePeople"
          hide-details="auto"
          class="mb-4"
          validate-on-blur
          :rules="[validators.required]"
          :readonly="!canEdit"
        ></v-text-field>
        <v-text-field
          v-model.number="muscle_mass"
          dense
          outlined
          type="number"
          :label="$t('MuscleMass')"
          :placeholder="$t('MuscleMass')"
          :prepend-inner-icon="icons.mdiHexagramOutline"
          hide-details="auto"
          class="mb-4"
          validate-on-blur
          :rules="[validators.required]"
          :readonly="!canEdit"
        ></v-text-field>
        <DatePicker
          v-model="date"
          :label="$t('DateMeasurement')"
          class="mb-2"
          :readonly="!canEdit || edit"
        />
        <v-row
          v-if="canEdit"
          class="mt-1"
        >
          <v-col>
            <v-btn
              color="primary"
              block
              type="submit"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="warning"
              block
              @click="$router.push({name: edit ? 'staff-town-exam-list' : 'staff-town-infirmary'})"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <ConfirmDialog
      v-if="isShowDeleteDialog"
      :title="$t('DeleteExamination')"
      :content="$t('DeleteExaminationContent')"
      @cancel="isShowDeleteDialog = false"
      @ok="handleRemove"
    ></ConfirmDialog>
  </v-card>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import AccountService from '@/services/AccountService'
import { required } from '@core/utils/validation'
import {
  mdiBloodBag, mdiHexagramOutline, mdiHumanMaleHeight, mdiNaturePeople, mdiTabMinus, mdiWeightLifter,
} from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  components: { DatePicker, ConfirmDialog },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      height: 0,
      weight: 0,
      blood_pressure: 0,
      blood_pressure_2: 0,
      body_fat: 0,
      muscle_mass: 0,
      date: new Date().toISOString().substr(0, 10),
      canDelete: true,
      canEdit: true,
      icons: {
        mdiHumanMaleHeight,
        mdiWeightLifter,
        mdiTabMinus,
        mdiBloodBag,
        mdiNaturePeople,
        mdiHexagramOutline,
      },
      validators: {
        required,
      },
      checkInId: null,
      userId: null,
      userData: {},
      isShowDeleteDialog: false,
    }
  },
  computed: {
    ...mapGetters('town', ['getExaminationItemData']),
    bmi() {
      const heightToMass = (this.height * this.height) / 10000

      return this.height > 0 ? Math.round((this.weight / heightToMass) * 100) / 100 : null
    },
  },
  created() {
    const { id } = this.$route.params
    try {
      const [userId, checkInId] = id.split('-')

      if (!userId || !checkInId) throw new Error('Params not found ')
      this.checkInId = checkInId
      this.userId = userId

      this.getUserData(userId)
      if (this.edit) {
        this.fetchExaminationById(checkInId).then(() => {
          this.height = this.getExaminationItemData.height
          this.weight = this.getExaminationItemData.weight
          this.blood_pressure = this.getExaminationItemData.blood_pressure
          this.blood_pressure_2 = this.getExaminationItemData.blood_pressure_2
          this.body_fat = this.getExaminationItemData.body_fat
          this.muscle_mass = this.getExaminationItemData.muscle_mass
          this.canDelete = this.getExaminationItemData.is_delete
          this.canEdit = this.getExaminationItemData.is_edit
          this.date = this.formatDate(this.getExaminationItemData.created_at * 1000)
        })
      }
    } catch (error) {
      this.$router.push({
        name: this.edit ? 'staff-town-exam-list' : 'staff-town-infirmary',
      })
    }
  },
  methods: {
    ...mapActions('town', ['saveExaminationById', 'removeExaminationById', 'waitExaminationById', 'fetchExaminationById', 'saveExamination', 'removeExamination']),
    handleSubmit() {
      const isValidForm = this.$refs.checkUpForm.validate()

      if (!isValidForm) {
        this.$store.commit('snackbar/show', {
          message: this.$t('RequiredFillData'),
          type: 'error',
        })

        return
      }

      const body = {
        height: `${this.height}`,
        weight: `${this.weight}`,
        blood_pressure: `${this.blood_pressure}`,
        blood_pressure_2: `${this.blood_pressure_2}`,
        body_fat: `${this.body_fat}`,
        muscle_mass: `${this.muscle_mass}`,
        created_at: this.date,
        id: this.checkInId,
      }

      const saveMethod = this.edit ? 'saveExaminationById' : 'saveExamination'

      if (this.edit) {
        delete body.created_at
      }

      this[saveMethod](body).then(() => {
        this.$store.commit('snackbar/show', {
          message: this.$t('SaveSuccess'),
          type: 'success',
        })
        this.$router.push({
          name: this.edit ? 'staff-town-exam-list' : 'staff-town-infirmary',
        })
      })
    },
    async getUserData(id) {
      try {
        const resp = await AccountService.getUserProfile({
          id_user: id,
        })

        const { data, result } = resp.data
        if (result === 'success') {
          this.$set(this, 'userData', data)
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
    handleRemove() {
      const removeMethod = this.edit ? 'removeExaminationById' : 'removeExamination'
      this[removeMethod](this.checkInId).then(() => {
        this.$store.commit('snackbar/show', {
          message: this.$t('RemoveSuccess'),
          type: 'success',
        })

        this.$router.push({
          name: this.edit ? 'staff-town-exam-list' : 'staff-town-infirmary',
        })
      })
    },
    handleWait() {
      this.waitExaminationById(this.checkInId).then(() => {
        this.$store.commit('snackbar/show', {
          message: this.$t('SaveSuccess'),
          type: 'success',
        })

        this.$router.push({
          name: 'staff-town-infirmary',
        })
      })
    },
  },
}
</script>
